import React from "react"
import { Tab, TabList, TabPanel } from "react-tabs"
import RotatingTabs  from "../components/rotating-tabs"

export default class FeatureTabs extends React.Component {
  render() {
    let { className, tabs } = this.props
    return (
      <React.Fragment>
        {/* =================================== These tabs render on mobile */}
        <div className="product-features-mobile">
          {tabs.map(t => (
            <div key={t.text} className="non-rotating-tabs ta-c">
              <img src={t.image} alt="" />

              <p dangerouslySetInnerHTML={{ __html: t.text }} />
            </div>
          ))}
        </div>
        {/* =================================== These tabs render on desktop */}
        <div className="product-features-desktop">
          <RotatingTabs className={className}>
            <TabList className="product-tabs__tabs column branded-ul">
              {tabs.map(t => (
                <Tab
                  key={t.text}
                  dangerouslySetInnerHTML={{ __html: t.text }}
                />
              ))}
            </TabList>
            <div className="product-tabs__panel-wrap tab-panel-1 column">
              {tabs.map(t => (
                <TabPanel key={t.text}>
                  <img src={t.image} alt="" />
                </TabPanel>
              ))}
            </div>
          </RotatingTabs>
        </div>
      </React.Fragment>
    )
  }
}
