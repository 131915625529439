import React from "react"
import { Tabs } from "react-tabs"
import { getTabsCount } from "react-tabs/lib/helpers/count.js"

export default class RotatingTabs extends React.Component {
  constructor(props) {
    super()
    this.state = { tabIndex: 0, tabCount: getTabsCount(props.children) }
    this.interval = setInterval(this.handleRotate.bind(this), 3500)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <Tabs
        {...this.props}
        selectedIndex={this.state.tabIndex}
        onSelect={this.handleSelect.bind(this)}
      >
        {this.props.children}
      </Tabs>
    )
  }

  handleSelect(tabIndex) {
    clearInterval(this.interval)
    this.setState({ tabIndex })
  }

  handleRotate() {
    let { tabIndex, tabCount } = this.state
    if (tabIndex < tabCount - 1) {
      tabIndex += 1
    } else {
      tabIndex = 0
    }
    this.setState({ tabIndex })
  }
}
